import type { Bot, BotRequestBody } from "~/entities/chatbots";
import { BaseCrudApi } from "./abstract/base-crud.api";
import { CatchAll } from "~/error/catch";

export class BotsApi extends BaseCrudApi {
  constructor() {
    super("bot");
  }

  @CatchAll()
  async getBots(take: string, skip: string) {
    return this.api.get<any>('',{ params: { take, skip } });
  }
  async getOneBot(id: string) {
    return this.api.get<Bot>(id);
  }

  @CatchAll()
  async createBot(data: BotRequestBody) {
    return this.api.post<any>('', data);
  }

  @CatchAll()
  async updateBot(id: string, data: BotRequestBody, userId: string, confirm: boolean) {
    const body = {
        ...data,
        userId :userId,
        id,
        confirm
    }
    return this.api.put(id, body)
  }

  @CatchAll()
  async putchBot(id: string, data: {
    status?: string,
    name?: string,
    channelId?: string,
    confirm: boolean,
  }) {
    return this.api.patch(id, data)
  }

  @CatchAll()
  async deleteBot(id: string) {
    return this.api.delete(id)
  }

  @CatchAll()
  async updateSchema(data: {
    botId: string;
    graph: string;
  }) {
    return this.api.patch(`/update-schema/${data.botId}`, data)
  }

  @CatchAll()
  async addFile(nodeId: string, file: any, id: string) {
    return this.api.post(
      `/upload-file/${id}?_nocache=${new Date().getTime()}`,
      {
        nodeId,
        headerFile: file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}
